import { useState } from "react";
import { browserHistory } from "react-router";
import giftImage from 'assets/images/gift_button.svg';
import birthdayCakeIage from 'assets/images/birthday_button.svg';
import lunarNewYearImage from 'assets/images/lunar_new_year_button.svg';
import trophyImage from 'assets/images/trophy_button.svg';
import embarkGiftCardImg from 'assets/images/embark_gifting.png';
import birthdayGiftCardImg from 'assets/images/birthday_gift_card.png';
import congratsGiftCardImg from 'assets/images/congrats_gift_card.svg';
import lunarNewYearGiftCardImage from 'assets/images/lunar_new_year_gift_card.svg';
import lunarNewYearSuccessImg from 'assets/images/red_envelope_success.svg';
import birthdaySuccessImg from 'assets/images/birthday_success.svg';
import congratsSuccessImg from 'assets/images/trophy_success.svg';
import giftSuccessImg from 'assets/images/gift_image.svg';

export const useGiftOccasion = ({ localized }) => {
  const [selectedOccasion, setSelectedOccasion] = useState(null);

  const occasionOptions = [
    {
      label: localized['giftOccasion.lunarNewYear.label'] || '',
      value: 'lunarNewYear',
      image: lunarNewYearImage,
      imageAlt: localized['giftOccasion.lunarNewYear.imgAlt'] || '',
      cardImage: lunarNewYearGiftCardImage,
      cardLabel: localized['giftOccasion.lunarNewYearGiftCard.label'] || '',
      cardImageAlt: localized['giftOccasion.lunarNewYearGiftCard.imgAlt'] || '',
      successImg: lunarNewYearSuccessImg
    },
    {
      label: localized['giftOccasion.congratulations.label'] || '',
      value: 'congratulations',
      image: trophyImage,
      imageAlt: localized['giftOccasion.congratulations.imgAlt'] || '',
      cardImage: congratsGiftCardImg,
      cardLabel: localized['giftOccasion.congratsGiftCard.label'] || '',
      cardImageAlt: localized['giftOccasion.congratsGiftCard.imgAlt'] || '',
      successImg: congratsSuccessImg
    },
    {
      label: localized['giftOccasion.birthday.label'] || '',
      value: 'birthday',
      image: birthdayCakeIage,
      imageAlt: localized['giftOccasion.birthday.imgAlt'] || '',
      cardImage: birthdayGiftCardImg,
      cardLabel: localized['giftOccasion.brithdaygGiftCard.label'] || '',
      cardImageAlt: localized['giftOccasion.brithdaygGiftCard.imgAlt'] || '',
      successImg: birthdaySuccessImg
    },
    {
      label: localized['giftOccasion.gift.label'] || '',
      value: 'gift',
      image: giftImage,
      imageAlt: localized['giftOccasion.gift.imgAlt'] || '',
      cardImage: embarkGiftCardImg,
      cardLabel: '',
      cardImageAlt: localized['img.alt.embarkGift'] || '',
      successImg: giftSuccessImg
    }
  ];

  const continueToDetails = () => {
    // logComponentEvent('GiftingOccasion', 'ContinueClicked', { data: { button: 'Continue' } });
    if (!selectedOccasion) setSelectedOccasion(occasionOptions[3]);
    browserHistory.push('/gift/details');
  };

  return {
    selectedOccasion,
    setSelectedOccasion,
    continueToDetails,
    occasionOptions
  };
};
