import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ChipList from 'components/common/ChipList';
import TextField from 'components/common/TextField';
import Button from 'components/common/Button';
import ProfileImage from 'components/common/ProfileImage';
import Alert from 'components/common/Alert';
import CurrencyTextField from 'components/common/CurrencyTextField/CurrencyTextField';
import IconBanner from 'components/common/IconBanner';
import GiftCircleImg from 'assets/images/gift_circle.svg';
import AccountToolbar from 'components/common/AccountToolbar';
import { formatCurrency } from 'services/currency';
import { browserHistory } from 'react-router';
import { MAXIMUM_CHARACTER_COUNT } from 'components/Gift/services/constants';
import { logComponentEvent } from 'services/logging/componentEvent';
import './styles/giftDetails.scss';
export default function GiftDetails({
  locale,
  localized,
  beneficiary,
  giftOptions,
  checkoutMessage,
  isSubmitLoading,
  selectedGiftAmount,
  isAmountEmptyMessage,
  amountError,
  messageError,
  updateMessage,
  updateGiftAmount,
  selectedGiftOption,
  updateGiftOption,
  onDetailsNext,
  isGiftingOccasionEnabled,
  selectedOccasion
}) {
  useEffect(() => {
    logComponentEvent('GiftingAmount', 'PageLoaded');
  }, []);

  return (
    <div className='gift-details'>
      <AccountToolbar
        title={localized['giftDetails.title'] || ''}
        onBackClick={() => {
          browserHistory.push(`/gift${isGiftingOccasionEnabled ? '/occasion' : ''}`);
          logComponentEvent(
            'GiftingAmount',
            'BackClicked',
            { data: { button: 'Back' } }
          );
        }}
        className='gift-details__toolbar'
        showBackButton
      />
      <IconBanner
        title={localized['giftDetails.banner.title'] || ''}
        className='gift-details__banner'
        description={localized['giftDetails.banner.description'] || ''}
        imgSrc={GiftCircleImg}
        imgAlt={localized['img.alt.giftCircle']}
      />
      {selectedOccasion &&
        <img
          src={selectedOccasion?.cardImage || ''}
          alt={selectedOccasion?.cardImageAlt || ''}
          className='gift-details__occasion-card'
        />
      }
      <section className='gift-details__amount'>
        <ProfileImage
          name={{
            first: beneficiary?.firstName,
            last: beneficiary.lastInitial
          }}
          profileImageSrc={beneficiary?.profilePictureUrl}
          isUploadPictureEnabled={false}
        />
        <p className='gift-details__amount__total'>
          {formatCurrency(selectedGiftAmount, locale)}
        </p>
        <div className='gift-details__amount__input'>
          <span className='gift-details__amount__input__label'>
            {localized['giftDetails.amount.input.label'] || ''}
          </span>

          <div className='gift-details__amount__input__container'>
            {amountError?.message === 'giftDetails.error.amountEmpty' && (
              <Alert
                title={localized[amountError.message]}
                severity={amountError.severity}
                className='gift-details__amount__input__container__alert'
              />
            )}
            <div className='gift-details__amount__input__container__options'>
              <ChipList
                onChange={updateGiftOption}
                options={giftOptions}
                selectedValue={selectedGiftOption}
                error={amountError?.message}
                showErrorMessage={false}
                localized={localized}
              />
              {selectedGiftOption === 'custom' && (
                <CurrencyTextField
                  className='gift-details__amount__input__container__options__custom-input'
                  value={selectedGiftAmount}
                  error={!isAmountEmptyMessage ? localized[amountError?.message] : ''}
                  isInErrorState={!!amountError?.severity}
                  label={localized['giftDetails.amount.input.textFieldLabel'] || ''}
                  onChange={(e) => updateGiftAmount(e.value)}
                  fixedDecimalScale
                  allowNegative={false}
                  decimalScale={2}
                />
              )}
            </div>
          </div>
        </div>
      </section>
      <section className='gift-details__message'>
        <p className='gift-details__message__label'>
          {localized['giftDetails.about.label'] || ''}
        </p>
        <p className='gift-details__message__description'>
          {localized['giftDetails.about.description'] || ''}
        </p>
        <TextField
          value={checkoutMessage}
          label={localized['giftDetails.message.input.label'] || ''}
          placeholder={localized['giftDetails.message.input.placeholder'] || ''}
          onChange={(event) => updateMessage(event.target.value)}
          isInErrorState={!!messageError?.severity}
          multiline
        />
        <div className={`
          gift-details__message__footer 
          ${!!messageError.message && 'gift-details__message__footer--error'}
        `}>
          <span>
            {localized[messageError?.message] || ''}
          </span>
          <span>
            {checkoutMessage.length}/{MAXIMUM_CHARACTER_COUNT}
          </span>
        </div>
      </section>
      <Button
        size='large'
        variant='tonal'
        className='gift-details__checkout-btn'
        isLoading={isSubmitLoading}
        onClick={onDetailsNext}
      >
        {localized['continue'] || ''}
      </Button>
    </div>
  );
}

GiftDetails.propTypes = {
  locale: PropTypes.string,
  localized: PropTypes.object,
  beneficiary: PropTypes.object,
  checkoutMessage: PropTypes.string,
  giftOptions: PropTypes.array,
  isSubmitLoading: PropTypes.bool,
  selectedGiftAmount: PropTypes.number,
  isAmountEmptyMessage: PropTypes.bool,
  amountError: PropTypes.object,
  messageError: PropTypes.object,
  updateMessage: PropTypes.func,
  updateGiftAmount: PropTypes.func,
  selectedGiftOption: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  updateGiftOption: PropTypes.func,
  onDetailsNext: PropTypes.func,
  isGiftingOccasionEnabled: PropTypes.bool,
  selectedOccasion: PropTypes.object
};
