import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import giftImage from 'assets/images/gift_image.svg';
import Button from 'components/common/Button';
import bgImage1 from 'assets/images/bg-image1.svg';
import bgImage2 from 'assets/images/bg-image2.svg';
import { logComponentEvent } from 'services/logging/componentEvent';
import './styles/giftSuccess.scss';

export default function GiftSuccess({ localized, onSuccessDoneClick, occasionOptions, selectedOccasion, setSelectedOccasion, location }) {
  useEffect(() => {
    logComponentEvent('GiftingSuccess', 'PageLoaded');
    const occasionParam = location.query['occasion'] || 'gift';
    const occasion = occasionOptions.filter((occasion) => occasion.value === occasionParam)[0];
    occasion && setSelectedOccasion(occasion);
  }, []);

  return (
    <div className='gift-success'>
      <img src={bgImage1} alt='square' className='gift-success__bg1' />
      <img src={bgImage2} alt='semi-annulus' className='gift-success__bg2'/>
      <img
        className='gift-success__hero-img'
        alt={selectedOccasion?.imageAlt || ''}
        src={selectedOccasion?.successImg || ''}
      />
      <h1 className='gift-success__heading'>
        {selectedOccasion?.value === 'lunarNewYear'
          ? <>
              <span>{localized['gifting.success.header.lunarNewYear.one'] || ''}</span>
              <span className='gift-success__heading--lunar-new-year'>
                {localized['gifting.success.header.lunarNewYear.two'] || ''}
              </span>
              <span>{localized['gifting.success.header.lunarNewYear.three'] || ''}</span>
            </>
          : <>{localized['gifting.success.header'] || ''}</>
        }
      </h1>
      <p className='gift-success__message'>
        {localized['gifting.success.body'] || ''}
      </p>
      <Button
        onClick={onSuccessDoneClick}
        className='gift-success__button'
        variant='tonal'
      >
        {localized['gifting.success.button'] || ''}
      </Button>
    </div>
  );
}

GiftSuccess.propTypes = {
  localized: PropTypes.object,
  onSuccessDoneClick: PropTypes.func,
  occasionOptions: PropTypes.array,
  selectedOccasion: PropTypes.object,
  setSelectedOccasion: PropTypes.func,
  location: PropTypes.object
};
