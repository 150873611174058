import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Button from "components/common/Button";
import AccountToolbar from "components/common/AccountToolbar";
import IconBanner from "components/common/IconBanner";
import partyPopperImg from 'assets/images/party_popper.svg';
import { browserHistory } from "react-router";
import IconButton from "components/common/IconButton";
import { logComponentEvent } from "services/logging/componentEvent";
import './styles/giftOccasion.scss';

export default function GiftOccasion({
  localized,
  selectedOccasion,
  setSelectedOccasion,
  continueToDetails,
  occasionOptions,
  isGiftingOccasionEnabled
}) {
  useEffect(() => {
    // logComponentEvent('GiftingOccasion', 'PageLoaded');
    !isGiftingOccasionEnabled && browserHistory.push('/gift');
  }, []);

  return isGiftingOccasionEnabled ? (
    <div className='gift-occasion'>
      <AccountToolbar
        title={localized['giftOccasion.title'] || ''}
        onBackClick={() => {
          // logComponentEvent(
          //   'GiftingOccasion',
          //   'BackClicked',
          //   { data: { button: 'Back' } }
          // );
          browserHistory.push('/gift');
        }}
        className='gift-occasion__toolbar'
        showBackButton
      />
      <IconBanner
        className='gift-occasion__banner'
        title={localized['giftOccasion.header'] || ''}
        description={localized['giftOccasion.body'] || ''}
        imgSrc={partyPopperImg}
        imgAlt={localized['giftOccasion.header.imgAlt']}
      />
      <div className='gift-occasion__content'>
        <section className='gift-occasion__content__list'>
          <h2 className='gift-occasion__content__list__heading'>
            {localized['giftOccasion.listHeader'] || ''}
          </h2>
          <div className='gift-occasion__content__list__container'>
            {occasionOptions && occasionOptions.map((occasion) => {
              return (
                <IconButton
                  key={occasion.value}
                  onClick={() => setSelectedOccasion(occasion)}
                  className={
                    `gift-occasion__content__list__container__item${
                      selectedOccasion?.value === occasion?.value
                        ? ' gift-occasion__content__list__container__item--selected'
                        : ''
                      }
                    `
                  }
                >
                  <img className='gift-occasion__content__list__container__item__img' src={occasion.image} />
                  <span className='gift-occasion__content__list__container__item__label'>{occasion.label}</span>
                </IconButton>
              );
            })}
          </div>
        </section>
      </div>
      <Button
        size='large'
        variant='tonal'
        className='gift-occasion__btn'
        onClick={continueToDetails}
      >
        {localized['continue'] || ''}
      </Button>
    </div>
  ) : <></>;
}

GiftOccasion.propTypes = {
  localized: PropTypes.object,
  selectedOccasion: PropTypes.object,
  setSelectedOccasion: PropTypes.func,
  continueToDetails: PropTypes.func,
  occasionOptions: PropTypes.array,
  isGiftingOccasionEnabled: PropTypes.bool
};
